import { render, staticRenderFns } from "./BusinessTitleBanner.vue?vue&type=template&id=fc34f928&scoped=true&"
import script from "./BusinessTitleBanner.vue?vue&type=script&lang=ts&"
export * from "./BusinessTitleBanner.vue?vue&type=script&lang=ts&"
import style0 from "./BusinessTitleBanner.vue?vue&type=style&index=0&id=fc34f928&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc34f928",
  null
  
)

export default component.exports