



















import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class BusinessTitleBanner extends Vue {
  @Prop()
  title: string;
  @Prop({ default: false })
  center: boolean;
  @Prop({ default: false })
  isAcquisition: boolean;
  @Prop({ default: false })
  isSupportBanner: boolean;
  @Prop({ default: false })
  isFoscBanner: boolean;
  @Prop({ default: false })
  isVoiceBanner: boolean;
  @Prop({ default: false })
  titleWeightNormal: boolean;
  @Prop({ default: false })
  isNewBanner: boolean;
}
